.overflow_body {
	overflow: hidden;
}

.language-switcher {
	display: flex;
	align-items: center;
	position: relative;
	font-weight: 600;
	font-size: 16px;
	color: #262C37;
	cursor: pointer;
	z-index: 999;
}

.language-list {
	opacity: 0;
	pointer-events: none;
	transition: .3s;
	position: absolute;
	top: 100%;
	left: 0;
	padding: 5px 0;
	margin: 0;
	list-style: none;

	li {

		a {
			font-weight: 600;
			font-size: 16px;
			line-height: 26px;
			color: #262C37;
		}
	}
}

.language-switcher {

	&.active .language-list {
		opacity: 1;
		pointer-events: visible;
	}

	.arrow {
		display: flex;
		align-items: center;
		flex-shrink: 0;
		margin-left: 5px;
	}
}

.smart {
	width: 265px;
	height: 574px;
	box-sizing: content-box;
	position: relative;
	@include media-breakpoint-down-992 {
		width: 164px;
		height: 356px;
		margin: 0 auto;
		left: -10px;
	}

	&:after {
		content: '';
		border: 17px solid #6E778D;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border-radius: 28px;
		z-index: 99;
		@include media-breakpoint-down-992 {
			border-radius: 15px;
			border: 10px solid #6E778D;
		}
	}

	&:before {
		content: '';
		background: url("../img/blot-hero.svg") no-repeat;
		background-size: contain;
		position: absolute;
		left: -320px;
		top: -30px;
		width: 966px;
		height: 907px;
		pointer-events: none;
		@include media-breakpoint-down-992 {
			top: -150px;
			left: -250px;
			width: 770px;
			height: 732px;
		}
		@include media-breakpoint-down-575 {
			z-index: 99;
		}
		@media (max-width: 450px) {
			content: none;
		}
	}

	img {
		border-radius: 10px;
		position: relative;
		width: 100%;
		height: 100%;
		padding: 17px;
		@include media-breakpoint-down-992 {
			padding: 10px;
			border-radius: 15px;
		}
		@include media-breakpoint-down-575 {
			position: relative;
			z-index: 99;
		}
	}
}

.wrapper_smart {
	position: relative;

	@media (max-width: 450px) {
		&:before {
			content: '';
			position: absolute;
			background: url("../img/blot-hero-mob-2.svg") no-repeat center center;
			top: -130px;
			left: 0;
			width: 100vw;
			height: 800px;
			background-size: cover;
			margin-left: -10px;
			margin-right: -10px;
		}
	}
}

.hero {
	padding-bottom: 168px;
	@include media-breakpoint-down-992 {
		padding-bottom: 134px;
	}
	@include media-breakpoint-down-575 {
		padding-bottom: 93px;
	}

	@media (min-width: 1300px) {
		.row {
			.col-xl-9 {
				flex: 0 0 70%;
				max-width: 70%;
			}

			.col-xl-3 {
				flex: 0 0 30%;
				max-width: 30%;
			}
		}
	}

	.wrapper-bottom {
		display: flex;

		.left-box {
			max-width: 440px;
			margin-right: 25px;
			@include media-breakpoint-down-768 {
				margin-right: 0;
			}

			.subtitle {
				font-size: 16px;
				line-height: 1.3;
				color: #222222;
				@include media-breakpoint-down-992 {
					line-height: 1.5;
				}
			}

			.btn {
				margin-top: 45px;
				@include media-breakpoint-down-992 {
					margin-top: 28px;
					margin-bottom: 69px;
				}
				@include media-breakpoint-down-768 {
					margin-top: 39px;
					width: 100%;
					justify-content: center;
				}
			}
		}

		.qrcode {
			max-width: 160px;
			position: relative;
			z-index: 9;
			@include media-breakpoint-down-992 {
				display: none;
			}
		}
	}

	.content-box {
		padding-top: 125px;
		@include media-breakpoint-down-992 {
			padding-top: 98px;
		}
		@include media-breakpoint-down-575 {
			padding-top: 69px;
		}
	}
}

.stats {
	padding-bottom: 160px;
	@include media-breakpoint-down-992 {
		padding-bottom: 99px;
	}
	@include media-breakpoint-down-575 {
		padding-bottom: 82px;
	}

	.col-xl-4 {
		@include media-breakpoint-down-575 {
			&:last-child {
				margin-top: 25px;
			}
		}
	}

	.item {
		.title {
			font-weight: 600;
			font-size: 20px;
			text-transform: uppercase;
			color: #589CE6;
			margin-bottom: 19px;
			@include media-breakpoint-down-992 {
				font-size: 16px;
			}
		}

		.subtitle {
			font-weight: 600;
			font-size: 16px;
			line-height: 1.5;
			color: #222222;
		}
	}
}

.wdroider-functions {
	padding-bottom: 75px;
	@include media-breakpoint-down-992 {
		padding-bottom: 61px;
	}
	@include media-breakpoint-down-575 {
		padding-bottom: 38px;
	}

	.item {
		margin-bottom: 60px;
		@include media-breakpoint-down-992 {
			margin-bottom: 40px;
		}

		.slash {
			font-weight: 600;
			font-size: 20px;
			text-transform: uppercase;
			color: #B235FF;
			margin-bottom: 38px;
			@include media-breakpoint-down-992 {
				margin-bottom: 27px;
			}
		}

		.title-h4 {
			font-weight: 600;
			font-size: 22px;
			line-height: 1.3;
			color: #262C37;
			margin-bottom: 35px;
			display: flex;
			height: 57px;
			align-items: baseline;
			@include media-breakpoint-down-992 {
				font-size: 18px;
			}
			@include media-breakpoint-down-575 {
				height: auto;
				margin-bottom: 20px;
				br {
					display: none;
				}
			}

			.badge {
				margin-left: 5px;
				font-weight: 600;
				font-size: 22px;
				color: #589CE6;
				text-transform: uppercase;
			}
		}

		p {
			max-width: 550px;
			line-height: 1.4;
		}
	}
}

.no-ads {
	background: #F2F6FF;
	padding-top: 94px;
	overflow: hidden;
	@include media-breakpoint-down-992 {
		padding-top: 64px;
	}
	@include media-breakpoint-down-575 {
		padding-top: 47px;
	}

	.content-box {
		padding-top: 35px;

		.title-h2 {
			margin-bottom: 32px;
		}

		p {
			max-width: 500px;
			margin-bottom: 48px;
			@include media-breakpoint-down-575 {
				margin-bottom: 31px;
			}
		}
	}

	.smart {
		margin-bottom: -75px;
		margin-left: 10px;
		@include media-breakpoint-down-992 {
			margin-top: 55px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: -106px;
			width: 265px;
			height: auto;
		}

		img {
			width: 100%;
			height: 100%;
			@include media-breakpoint-down-992 {
				padding: 15px;
			}
		}

		&:after {
			@include media-breakpoint-down-992 {
				border: 15px solid #6e778d;
			}
		}

		&:before {
			top: -230px;
			@include media-breakpoint-down-992 {
				top: -100px;
			}
		}
	}

	&.no-ads-border {
		.smart {
			@include media-breakpoint-down-575 {
				width: 164px;
				height: 345px;
				margin-bottom: -51px;
			}

			&:after {
				border-color: #DEE7FF;
				@include media-breakpoint-down-575 {
					border: 10px solid #DEE7FF;
					border-radius: 10px;
				}
			}

			img {
				@include media-breakpoint-down-575 {
					padding: 10px;
				}
			}
		}
	}

	&.app {

		.content-box {
			padding-top: 79px;
			@include media-breakpoint-down-992 {
				padding-top: 35px;
			}

			.title-h2 {
				margin-bottom: 40px;
				@include media-breakpoint-down-992 {
					max-width: 500px;
					margin-bottom: 30px;
				}
				@include media-breakpoint-down-575 {
					max-width: 200px;
				}
			}
		}

		.smart {
			@include media-breakpoint-down-992 {
				width: 200px;
				margin-bottom: -106px;
				margin-top: 80px;
			}
			@include media-breakpoint-down-575 {
				left: -15px;
			}
			@media (max-width: 450px) {
				margin-bottom: -200px;
			}

			&:before {
				@include media-breakpoint-down-992 {
					top: -163px;
					background: url("../img/blot-hero-mob.svg") no-repeat;
				}
				@media (max-width: 450px) {
					background: url("../img/blot-hero-mob-2.svg") no-repeat;
					left: -73px;
					background-size: cover;
				}
			}
		}

		.wrapper-btn {
			display: flex;

			.qrcode {
				max-width: 166px;
				margin-left: 90px;
				position: relative;
				top: -70px;
				@include media-breakpoint-down-992 {
					display: none;
				}
			}
		}
	}

	&.notice-banner {
		@include media-breakpoint-down-992 {
			padding-top: 39px;
		}
		@include media-breakpoint-down-575 {
			padding-top: 12px;
		}

		.content-box {
			p {
				@include media-breakpoint-down-992 {
					max-width: 300px;
					br {
						display: none;
					}
				}
			}
		}

		.smart {
			margin-bottom: -269px;
			@include media-breakpoint-down-992 {
				margin-bottom: -91px;
				width: 183px;
				height: 397px;
				left: -15px;
			}

			&:after {
				border-color: #DEE7FF;
			}

			&:before {
				background-image: url("../img/blot-notice.svg");
				width: 990px;
				height: 400px;
				top: -94px;
				@include media-breakpoint-down-992 {
					background-image: url("../img/blot-hero-mob-3.svg");
				}
				@include media-breakpoint-down-768 {
					background-image: url("../img/blot-hero-mob-4.svg");
					left: -173px;
					background-size: cover;
				}
				@media (max-width: 450px) {
					background-size: cover;
					width: 120vw;
					height: 180%;
					left: -46.5%;
					top: 0;
				}
			}
		}

		.content-box {
			padding-top: 0;
			margin-top: -14px;
			@include media-breakpoint-down-992 {
				margin-top: 40px;
			}

			.title-h2 {
				margin-bottom: 22px;
			}
		}
	}
}

.privacy-security {
	padding: 130px 0 160px 0;
	@include media-breakpoint-down-992 {
		padding: 100px 0 93px 0;
	}
	@include media-breakpoint-down-575 {
		padding: 81px 0 70px 0;
	}

	.circle {
		width: 100%;
		height: 100%;
		aspect-ratio: 1;
		border: 1px solid #B235FF;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		padding: 40px;
		font-family: 'Inter', sans-serif;
	}

	.row {
		@include media-breakpoint-down-992 {
			justify-content: center;
		}

		.col-xl-4 {
			@include media-breakpoint-down-575 {
				margin-bottom: 10px;
			}
		}
	}
}

.personal-information {
	padding-bottom: 160px;
	position: relative;
	@include media-breakpoint-down-992 {
		padding-bottom: 125px;
	}
	@include media-breakpoint-down-575 {
		padding-bottom: 80px;
	}

	&:after {
		content: '';
		position: absolute;
		background: url("../img/blot-1.svg") no-repeat;
		width: 741px;
		height: 741px;
		left: 50%;
		transform: translate(-50%, 0);
		margin-left: 450px;
		bottom: -47px;
		pointer-events: none;
		z-index: -1;
		@include media-breakpoint-down-992 {
			margin-left: 250px;
			bottom: -142px;
		}
		@include media-breakpoint-down-575 {
			content: none;
		}
	}

	.row {
		p {
			line-height: 1.6;
			max-width: 510px;
			font-family: 'Inter', sans-serif;
			@include media-breakpoint-down-992 {
				max-width: none;
				margin-bottom: 30px;
			}
		}
	}

	.title-h2 {
		@include media-breakpoint-down-992 {
			margin-bottom: 35px;
		}
	}
}

.module-info-window {
	background: #F2F6FF;
	border-radius: 10px;
	padding: 52px 40px 67px 40px;
	max-width: 818px;
	margin-top: 40px;
	position: relative;
	@include media-breakpoint-down-992 {
		margin-top: 6px;
		max-width: 540px;
		padding: 41px 70px 34px 28px;
	}

	p {
		font-style: italic;
		font-weight: 600;
		font-size: 16px;
		line-height: 1.2;
		color: #222222;
		margin-bottom: 40px;
		@include media-breakpoint-down-992 {
			max-width: 300px;
			line-height: 1.5;
			margin-bottom: 23px;
		}
	}

	pre {
		font-weight: 500;
		font-size: 16px;
		line-height: 1.2;
		color: #222222;
		margin-left: -15px;
		font-family: 'Inter', sans-serif;
		@include media-breakpoint-down-992 {
			white-space: pre-line;
			word-break: break-word;
			margin-left: 2px;
			line-height: 1.5;
		}

		.text-1 {
			color: #005597;
		}

		.text-2 {
			color: #B235FF;
		}

		.text-3 {
			color: #006434;
		}
	}

	&:after {
		@include media-breakpoint-down-575 {
			content: '';
			position: absolute;
			background: url("../img/blot-1.svg") no-repeat;
			width: 741px;
			height: 741px;
			left: 50%;
			transform: translate(-50%, -50%);
			top: 50%;
			pointer-events: none;
			z-index: -1;
		}
	}
}

.safety {
	padding-bottom: 160px;
	position: relative;
	@include media-breakpoint-down-992 {
		padding-bottom: 98px;
	}
	@include media-breakpoint-down-575 {
		padding-bottom: 81px;
	}

	.title-h2 {
		@include media-breakpoint-down-992 {
			max-width: 500px;
			margin-bottom: 38px;
		}
	}

	&:before {
		content: '';
		position: absolute;
		background: url("../img/blot-2.svg") no-repeat;
		width: 741px;
		height: 741px;
		left: 50%;
		transform: translate(-50%, 0);
		margin-left: -450px;
		bottom: -47px;
		pointer-events: none;
		z-index: -1;
		@include media-breakpoint-down-992 {
			content: none;
		}
	}

	.content-box {
		margin-left: auto;
		max-width: 420px;
		font-family: 'Inter', sans-serif;
		margin-right: 140px;
		@include media-breakpoint-down-992 {
			max-width: none;
			margin-right: 0;
		}

		p {
			margin-bottom: 28px;
			line-height: 1.6;
		}

		a {
			transition: .3s;

			&:hover {
				opacity: .7;
			}
		}
	}
}

.premium-features {
	padding-bottom: 82px;
	@include media-breakpoint-down-992 {
		padding-bottom: 69px;
	}

	.title-h2 {
		@include media-breakpoint-down-992 {
			margin-bottom: 38px;
		}
	}

	.text {
		max-width: 41%;
		font-weight: 400;
		font-size: 16px;
		line-height: 1.6;
		color: #262C37;
		font-family: 'Inter', sans-serif;
		margin-bottom: 60px;
		@include media-breakpoint-down-992 {
			max-width: none;
			margin-bottom: 35px;
		}
	}

	.title-h3 {
		font-weight: 600;
		font-size: 22px;
		line-height: 30px;
		color: #262C37;
		margin-bottom: 30px;
		font-family: 'Inter', sans-serif;
		@include media-breakpoint-down-992 {
			font-size: 18px;
		}
	}

	.item {
		margin-bottom: 60px;
		@include media-breakpoint-down-992 {
			margin-bottom: 30px;
		}

		.number {
			font-weight: 600;
			font-size: 16px;
			line-height: 26px;
			color: #589CE6;
			margin-bottom: 20px;
			@include media-breakpoint-down-992 {
				margin-bottom: 9px;
			}
		}

		p {
			font-family: 'Inter', sans-serif;
			line-height: 1.5;
		}
	}
}

.section-single {
	padding-top: 35px;
	position: relative;
	@include media-breakpoint-down-992 {
		padding-top: 51px;
	}
	@media (max-width: 450px) {
		&:before {
			content: '';
			top: -50px;
			left: 0;
			position: absolute;
			width: 120vw;
			height: 100%;
			background: url("../img/blot-hero-mob-5.svg") no-repeat;
			z-index: -1;
			background-size: contain;
		}
	}

	h1 {
		font-weight: 600;
		font-size: 46px;
		line-height: 1.4;
		color: #283148;
		margin-bottom: 55px;
		max-width: 90%;
		@include media-breakpoint-down-992 {
			font-size: 36px;
			line-height: 1.3;
			margin-bottom: 36px;
		}
		@include media-breakpoint-down-575 {
			font-size: 26px;
		}
	}

	.top-info {

		ul {
			padding: 34px 0 60px 0;
			margin-bottom: 40px;
			border-top: 1px solid #E7EAEF;
			border-bottom: 1px solid #E7EAEF;
			position: relative;
			z-index: 99;
			@include media-breakpoint-down-992 {
				padding: 29px 0 60px 0;
			}
			@include media-breakpoint-down-575 {
				padding: 18px 0 54px 0;
			}

			li {
				margin-bottom: 5px;
				list-style: none;

				&:last-child {
					margin-bottom: 0;
				}

				a {
					font-weight: 400;
					font-size: 16px;
					line-height: 1.3;
					color: #614BFF;
					font-family: 'Inter', sans-serif;
					transition: .3s;

					&:hover {
						color: #222222;
					}
				}
			}
		}

		.top-image {
			img {
				border-radius: 20px;
			}

			.caption {
				color: #A1A1A1;
				margin-top: 10px;
				line-height: normal;
				@include media-breakpoint-down-575 {
					font-size: 14px;
				}
			}
		}
	}

	h2 {
		font-weight: 600;
		font-size: 32px;
		line-height: 1.4;
		color: #262C37;
		margin-bottom: 50px;
		margin-top: 80px;
		@include media-breakpoint-down-992 {
			font-size: 28px;
			margin-bottom: 28px;
			margin-top: 55px;
		}
		@include media-breakpoint-down-575 {
			font-size: 20px;
		}

		&:first-of-type {
			margin-top: 0;
		}
	}

	h3 {
		font-weight: 600;
		font-size: 22px;
		line-height: 30px;
		color: #262C37;
		border-top: 1px solid #E7EAEF;
		padding-bottom: 26px;
	}

	.module-alert {
		background: #F2F6FF;
		border-radius: 10px;
		padding: 35px 40px 35px 30px;
		display: flex;
		align-items: center;
		margin-bottom: 50px;
		@include media-breakpoint-down-575 {
			flex-direction: column;
			align-items: flex-start;
		}

		.icon {
			flex-shrink: 0;
			margin-right: 30px;
			@include media-breakpoint-down-575 {
				margin-right: 0;
				margin-bottom: 25px;
			}
		}
	}

	p {
		line-height: 1.55;

		a {
			color: #614BFF;
			transition: .3s;

			&:hover {
				color: #222222;
			}
		}
	}

	&.section-single-pl {
		.top-info {
			margin-bottom: 75px;
			@include media-breakpoint-down-992 {
				margin-bottom: 44px;
			}
			@include media-breakpoint-down-575 {
				margin-bottom: 56px;
			}

			h2 {
				margin-bottom: 15px;
				@include media-breakpoint-down-992 {
					margin-bottom: 29px;
				}
				@include media-breakpoint-down-575 {
					font-size: 20px;
				}
			}

			ul {
				margin-left: 300px;
				@include media-breakpoint-down-992 {
					margin-left: 0;
				}
			}

			.top-image {
				margin-left: 300px;
				position: relative;
				@include media-breakpoint-down-992 {
					margin-left: 0;
				}

				&:after {
					content: '';
					position: absolute;
					background: url("../img/blot-single.svg") no-repeat;
					width: 741px;
					height: 741px;
					left: 50%;
					transform: translate(-50%, -50%);
					margin-left: -550px;
					top: 55%;
					pointer-events: none;
					z-index: -1;
					@include media-breakpoint-down-992 {
						margin-left: 250px;
						top: -40%;
						z-index: 1;
					}
					@media (max-width: 450px) {
						content: none;
					}
				}
			}
		}

		.inner-box {
			> p {
				margin-bottom: 40px;

				&:first-of-type {
					@include media-breakpoint-down-992 {
						margin-bottom: 0;
					}
				}

				&:last-child {
					margin-bottom: 0;
				}
			}

			> h3 {
				padding-top: 30px;
			}

			> p, .module-alert, > h3, > .wrapper-posts {
				margin-left: 300px;
				@include media-breakpoint-down-992 {
					margin-left: 0;
				}
			}
		}

		&.section-single-2 {
			padding-top: 68px;
			@include media-breakpoint-down-992 {
				padding-top: 51px;
			}

			.top-image {
				margin-left: 0;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}

				&:after {
					content: none;
				}
			}

			.single-image {
				margin-bottom: 50px;
			}

			h1 {
				line-height: 1.33;
				margin-bottom: 68px;
				@include media-breakpoint-down-992 {
					margin-bottom: 36px;
				}
			}

			.top-info {
				margin-bottom: 86px;
				@include media-breakpoint-down-575 {
					margin-bottom: 56px;
				}
			}
		}
	}
}

.mb-1 {
	.inner-border {
		border-bottom: 1px solid #E7EAEF;
		padding-bottom: 100px;
		@include media-breakpoint-down-992 {
			padding-bottom: 30px;
			margin-bottom: 80px;
		}
		@include media-breakpoint-down-575 {
			margin-bottom: 40px;
		}
	}
}

.my-licenses {
	padding-top: 34px;
	@include media-breakpoint-down-992 {
		.inner-border {
			border: none;
			padding-bottom: 0;
		}
	}

	h1 {
		font-weight: 600;
		font-size: 46px;
		line-height: 1.5;
		color: #262C37;
		margin-bottom: 37px;
		@include media-breakpoint-down-992 {
			font-size: 36px;
		}
		@include media-breakpoint-down-575 {
			font-size: 26px;
			margin-bottom: 16px;
		}
	}
}

.breadcrumbs {
	display: flex;
	align-items: center;
	font-size: 16px;
	line-height: 1.2;
	margin-bottom: 66px;
	font-family: 'Inter', sans-serif;
	@include media-breakpoint-down-992 {
		margin-bottom: 37px;
	}

	@include media-breakpoint-down-575 {
		margin-bottom: 42px;
	}

	li {
		list-style: none;
	}

	span {
		color: #8F90A6;
	}

	a {
		color: #222222;
		position: relative;
		margin-right: 4px;
		display: inline-flex;
		transition: .3s;

		&:hover {
			color: #B235FF;
		}

		&:after {
			content: '/';
			color: #222222;
			padding-left: 4px;
		}
	}
}

.module-connected-devices {
	background: #F2F6FF;
	border-radius: 10px;
	padding: 45px 40px;
	display: grid;
	grid-template-columns: 47% 53%;
	position: relative;
	@include media-breakpoint-down-1200 {
		padding: 42px 31px;
		grid-template-columns: 60% 40%;
	}
	@include media-breakpoint-down-992 {
		display: block;
	}

	@include media-breakpoint-down-575 {
		padding: 33px 16px;
	}

	.left-box {
		border-right: 1px solid #E7EAEF;
		margin-right: 60px;
		@include media-breakpoint-down-992 {
			margin-right: 0;
			border: none;
		}
	}

	.right-box {
		padding-top: 10px;
		@include media-breakpoint-down-992 {
			padding-top: 34px;
			margin-left: 70px;
		}
		@include media-breakpoint-down-575 {
			margin-left: 0;
			text-align: center;
		}
	}

	.wdroider {
		display: flex;
		align-items: center;

		.icon {
			width: 53px;
			height: 53px;
			background: #589CE6;
			margin-right: 19px;
			flex-shrink: 0;
			font-weight: 600;
			font-size: 22px;
			color: #FFFFFF;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			border-radius: 50%;
			@include media-breakpoint-down-575 {
				margin-right: 14px;
			}
		}

		.title {
			font-weight: 600;
			font-size: 32px;
			line-height: 1.4;
			color: #262C37;
			@include media-breakpoint-down-992 {
				font-size: 24px;
			}
			@include media-breakpoint-down-575 {
				font-size: 20px;
			}
		}

		.sub-title {
			font-size: 16px;
			line-height: 25px;
			color: #435257;
		}
	}

	.info-line {
		font-size: 16px;
		line-height: 25px;
		color: #FF3B87;
		margin-top: 22px;
		margin-left: 70px;
		margin-bottom: 30px;
		@include media-breakpoint-down-992 {
			margin-top: 30px;
			margin-left: 70px;
			margin-bottom: 33px;
		}
		@include media-breakpoint-down-575 {
			margin-left: auto;
			margin-right: auto;
			text-align: center;
			max-width: 250px;
		}
	}

	.btn {
		margin-left: 70px;
		@include media-breakpoint-down-575 {
			margin-left: 0;
			padding: 0 15px;
			width: 100%;
			text-align: center;
			justify-content: center;
		}
	}

	h3 {
		font-weight: 600;
		font-size: 22px;
		line-height: 1.3;
		color: #262C37;
		margin-bottom: 10px;
		@include media-breakpoint-down-992 {
			font-size: 18px;
			margin-bottom: 24px;
		}
		@include media-breakpoint-down-575 {
			font-size: 16px;
			max-width: 200px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.list {
		li {
			list-style: none;
			font-size: 16px;
			line-height: 1.2;
			font-family: 'Inter', sans-serif;
			color: #435257;
			margin-bottom: 5px;
			@include media-breakpoint-down-575 {
				display: flex;
				flex-direction: column;
				margin-bottom: 14px;
			}

			span {
				margin-left: 5px;
				font-size: 13px;
				line-height: 1.2;
				color: #589CE6;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.authorize {
	padding: 40px 0 0 0;

	form {
		font-family: 'Inter', sans-serif;

		.btn {
			width: 100%;
			text-align: center;
			justify-content: center;
			margin-bottom: 20px;
		}

		.line {
			margin-bottom: 9px;
			display: flex;
			align-items: center;
			@include media-breakpoint-down-575 {
				flex-direction: column;
				align-items: flex-start;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		.link-1 {
			font-size: 16px;
			color: #262C37;
			border-bottom: 0.5px solid #262C37;
			transition: .3s;
			margin: 0 5px;
			@include media-breakpoint-down-575 {
				margin: 5px 0 5px 31px;
			}

			&:hover {
				border-bottom: 0.5px solid transparent;
			}
		}

		.link-2 {
			font-size: 16px;
			color: #278EDD;
			transition: .3s;
			@include media-breakpoint-down-575 {
				margin: 5px 0 5px 31px;
			}

			&:hover {
				opacity: .7;
			}
		}

		.module__check {
			.link-1 {
				margin: 0;
			}
		}
	}
}

.cookies {
	position: fixed;
	left: 50%;
	bottom: 15px;
	max-width: 900px;
	width: calc(100% - 20px);
	transform: translate(-50%, 0);
	background: #F2F6FF;
	border: 1px solid #DBDEE7;
	border-radius: 10px;
	z-index: 99;
	display: flex;
	align-items: center;
	padding: 25px 40px;
	@include media-breakpoint-down-575 {
		flex-direction: column;
		align-items: flex-start;
		padding: 20px;
	}

	p {
		font-weight: 600;
		font-size: 22px;
		line-height: 1.3;
		color: #222222;
		margin-right: 56px;
		@include media-breakpoint-down-575 {
			margin-right: 0;
			margin-bottom: 15px;
			font-size: 18px;
		}

		a {
			color: #614BFF;
			transition: .3s;
			border-bottom: 1px solid #614BFF;

			&:hover {
				border-bottom: 1px solid transparent;
			}
		}
	}
}

.module-author {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-top: 1px solid #e7eaef;
	border-bottom: 1px solid #e7eaef;
	margin-top: 70px;
	margin-bottom: 24px;
	padding: 25px 0 43px 0;
	@include media-breakpoint-down-768 {
		align-items: flex-start;
	}

	.wrapper-author {
		display: flex;
		align-items: center;
		@include media-breakpoint-down-768 {
			flex-direction: column;
			align-items: flex-start;
		}

		.author {
			margin-right: 10px;
			font-weight: 600;
			@include media-breakpoint-down-768 {
				margin-right: 0;
				margin-bottom: 10px;
			}
		}

		.name {
			color: #589CE6;
			transition: .3s;

			&:hover {
				opacity: .7;
			}
		}
	}

	.date {
		color: #435257;
	}

	&.module-author-2 {
		border: none;
		padding: 0;
		margin-top: 32px;
		margin-bottom: 31px;
	}
}

.pagination {
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #435257;
	margin-bottom: 80px;
	@include media-breakpoint-down-768 {
		margin-bottom: 40px;
		flex-direction: column;
		align-items: normal;
	}

	.title {
		margin-top: 10px;
		line-height: normal;
	}

	a {
		color: #614BFF;
		transition: .3s;

		&:hover {
			opacity: .7;
		}

		img {
			transition: .3s;

			&:hover {
				opacity: .7;
			}
		}
	}

	.next-post {
		text-align: right;
		@include media-breakpoint-down-768 {
			margin-top: 20px;
		}
	}
}

.module-post-related {
	@include media-breakpoint-down-992 {
		margin-bottom: 30px;
	}

	.title-h4 {
		font-size: 22px;
		font-style: normal;
		font-weight: 600;
		color: #262C37;
		margin-bottom: 9px;
		line-height: 1.4;
		min-height: 90px;
		@include media-breakpoint-down-992 {
			min-height: auto;
			br {
				display: none;
			}
		}
		@include media-breakpoint-down-575 {
			font-size: 20px;
		}
	}

	p {
		line-height: 1.6;
	}
}

.read-more {
	margin-top: 20px;
	color: #614BFF;
	display: inline-flex;
	transition: .3s;

	&:hover {
		opacity: .7;
	}
}

.related-articles {
	.title-h2 {
		margin-bottom: 56px;
		@include media-breakpoint-down-575 {
			margin-bottom: 30px;
		}
	}

	.col-xl-4 {
		@include media-breakpoint-down-992 {
			&:last-child {
				.module-post-related {
					margin-bottom: 0;
				}
			}
		}
	}
}

.module-post {
	padding-bottom: 60px;
	margin-bottom: 40px;
	border-bottom: 1px solid #e7eaef;

	&:last-child {
		padding-bottom: 0;
		margin-bottom: 0;
		border-bottom: 0;
	}

	.image {
		margin-bottom: 23px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 20px;
		}
	}

	.title-h4 {
		font-size: 32px;
		font-weight: 600;
		margin-bottom: 20px;
		line-height: 1.2;
	}
}
