@import 'inc/default';
@import 'inc/fonts';
@import 'inc/vars';
@import 'inc/bootstrap-grid';

body {
	color: $text;
	font-size: $bodyfontsize;
	font-family: $bodyfont;
}

@import 'layout';

main {
	position: relative;
	overflow: hidden;
}

.container {
	@include media-breakpoint-down-575 {
		padding-left: 10px;
		padding-right: 10px;
		.row {
			margin-left: -10px;
			margin-right: -10px;
			[class*="col-"] {
				padding-left: 10px;
				padding-right: 10px;
			}
		}
	}
}

@import 'parts/header';

@import 'parts/forms';
@import 'parts/typography';
@import 'parts/slider';
@import 'parts/popup';
@import 'parts/menu';
@import 'parts/cf7';

@import 'parts/footer';

@import 'media-extra';
