.main_menu {
	margin-left: 12px;
	@include media-breakpoint-down-992 {
		margin-left: 0;
		position: fixed;
		left: 0;
		top: 0;
		background: #fff;
		width: 100%;
		height: 100%;
		transform: translateX(-100%);
		transition: .3s;
		pointer-events: none;
		padding-top: 102px;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		overflow-y: auto;
		overflow-x: hidden;
		z-index: 9999;
		&.active {
			pointer-events: visible;
			transform: translateX(0);
		}
	}

	.icon {
		@include media-breakpoint-down-992 {
			transform: rotate(270deg);
		}
	}

	> ul {
		display: flex;
		align-items: center;
		@include media-breakpoint-down-992 {
			flex-direction: column;
			width: 100%;
			max-width: 300px;
		}

		> li {
			list-style: none;
			margin-right: 42px;
			position: relative;
			@include media-breakpoint-down-1200 {
				margin-right: 20px;
			}
			@include media-breakpoint-down-992 {
				margin-right: 0;
				margin-bottom: 24px;
			}

			&:last-child {
				margin-right: 0;
				@include media-breakpoint-down-992 {
					margin-bottom: 0;
				}
			}

			> a {
				font-weight: 600;
				font-size: 16px;
				line-height: 26px;
				color: #262C37;
				transition: .3s;
				@include media-breakpoint-down-992 {
					font-size: 17px;
				}

				&:hover {
					color: #B235FF;
				}
			}
		}
	}

	&.main_menu_footer {
		> ul {
			justify-content: flex-end;

			> li {
				> a {
					color: #FFFFFF;

					&:hover {
						color: #4BBD8C;
					}
				}
			}
		}
	}

	&.main_menu_2 {
		@include media-breakpoint-down-992 {
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;
		}
	}
}

.nesting {
	> a {
		display: flex;
		align-items: center;

		span {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 24px;
			height: 24px;
			transition: .3s;
		}
	}

	&:hover .sub_menu {
		opacity: 1;
		pointer-events: visible;
	}

	&:hover .icon {
		transform: rotate(180deg);
	}
}

.sub_menu {
	position: absolute;
	opacity: 0;
	pointer-events: none;
	padding: 24px 32px;
	background: #FFFFFF;
	box-shadow: 0px 10px 20px rgba(124, 124, 124, 0.15);
	border-radius: 20px;
	top: 105%;
	left: 50%;
	transform: translate(-50%, 0);
	min-width: 128px;
	transition: .3s;
	z-index: 99;

	li {
		list-style: none;
		margin-bottom: 24px;
		white-space: nowrap;
		text-align: left;

		&:last-child {
			margin-bottom: 0;
		}

		a {
			font-size: 17px;
			color: #65656C;
			transition: .3s;

			&:hover {
				color: #4BBD8C;
			}
		}
	}
}
