.title-h2 {
	font-weight: 600;
	font-size: 46px;
	line-height: 1.3;
	color: #262C37;
	margin-bottom: 70px;
	position: relative;
	z-index: 1;
	@include desktop-md {
		br {
			display: none;
		}
	}
	@include media-breakpoint-down-992 {
		font-size: 36px;
		margin-bottom: 57px;
	}
	@include media-breakpoint-down-575 {
		margin-bottom: 30px;
		font-size: 26px;
	}
}

.title-h1 {
	font-weight: 600;
	font-size: 46px;
	line-height: 1.3;
	color: #262C37;
	margin-bottom: 29px;
	position: relative;
	z-index: 1;
	@include desktop-md {
		br {
			display: none;
		}
	}
	@include media-breakpoint-down-992 {
		font-size: 36px;
	}
	@include media-breakpoint-down-575 {
		font-size: 25px;
	}
}

p {
	font-size: 16px;
	line-height: 1.4;
	color: #222222;
}

.btn {
	display: inline-flex;
	align-items: center;
	transition: .3s;
	height: 69px;
	background: #614BFF;
	border-radius: 50px;
	padding: 0 45px;
	font-weight: 700;
	font-size: 16px;
	text-transform: uppercase;
	color: #FFFFFF;
	white-space: nowrap;
	position: relative;
	z-index: 99;
	@include media-breakpoint-down-575 {
		text-align: center;
		justify-content: center;
		width: 100%;
	}

	&:hover {
		opacity: .7;
	}

	&.btn-border {
		color: #614BFF;
		border: 2px solid #614BFF;
		background: none;

		&:hover {
			opacity: 1;
			background: #614BFF;
			color: #FFFFFF;
		}
	}
}

