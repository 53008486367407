* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  line-height: 1;
  min-width: 320px;
  position: relative;
}

article,
aside,
details,
figure,
figcaption,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;
}

.hidden,
[hidden],
template {
  display: none;
}

a {
  text-decoration: none;
  background: transparent;
}

a:active, a:hover {
  outline: 0;
}

a img {
  border: 0;
}

b,
strong {
  font-weight: bold;
}

small {
  font-size: 75%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  border: 0;
  font: inherit;
  color: inherit;
  appearance: none;
  border-radius: 0;
  text-transform: none;
  box-sizing: border-box;
  outline: none !important;
  background-clip: padding-box;
  background-color: transparent;
}

button,
html input[type='button'] {
  cursor: pointer;
  appearance: button;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type='reset'], input[type='submit'] {
  cursor: pointer;
  appearance: button;
}

input[type='checkbox'], input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='number']::-webkit-inner-spin-button, input[type='number']::-webkit-outer-spin-button {
  height: auto;
}

input[type='search'] {
  appearance: textfield;
  box-sizing: border-box;
}

input[type='search']::-webkit-search-cancel-button, input[type='search']::-webkit-search-decoration {
  appearance: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

td,
th {
  padding: 0;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
  pointer-events: none;
  user-select: none;
  border: 0;
}

.clearfix {
  clear: both;
}

/*!
 * Bootstrap Grid v4.2.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 800px) {
  .container {
    max-width: 650px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1300px) {
  .container {
    max-width: 1190px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

body {
  color: #000;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}

.overflow_body {
  overflow: hidden;
}

.language-switcher {
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 600;
  font-size: 16px;
  color: #262C37;
  cursor: pointer;
  z-index: 999;
}

.language-list {
  opacity: 0;
  pointer-events: none;
  transition: .3s;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 5px 0;
  margin: 0;
  list-style: none;
}

.language-list li a {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #262C37;
}

.language-switcher.active .language-list {
  opacity: 1;
  pointer-events: visible;
}

.language-switcher .arrow {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: 5px;
}

.smart {
  width: 265px;
  height: 574px;
  box-sizing: content-box;
  position: relative;
}

@media (max-width: 992px) {
  .smart {
    width: 164px;
    height: 356px;
    margin: 0 auto;
    left: -10px;
  }
}

.smart:after {
  content: '';
  border: 17px solid #6E778D;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 28px;
  z-index: 99;
}

@media (max-width: 992px) {
  .smart:after {
    border-radius: 15px;
    border: 10px solid #6E778D;
  }
}

.smart:before {
  content: '';
  background: url("../img/blot-hero.svg") no-repeat;
  background-size: contain;
  position: absolute;
  left: -320px;
  top: -30px;
  width: 966px;
  height: 907px;
  pointer-events: none;
}

@media (max-width: 992px) {
  .smart:before {
    top: -150px;
    left: -250px;
    width: 770px;
    height: 732px;
  }
}

@media (max-width: 575px) {
  .smart:before {
    z-index: 99;
  }
}

@media (max-width: 450px) {
  .smart:before {
    content: none;
  }
}

.smart img {
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 17px;
}

@media (max-width: 992px) {
  .smart img {
    padding: 10px;
    border-radius: 15px;
  }
}

@media (max-width: 575px) {
  .smart img {
    position: relative;
    z-index: 99;
  }
}

.wrapper_smart {
  position: relative;
}

@media (max-width: 450px) {
  .wrapper_smart:before {
    content: '';
    position: absolute;
    background: url("../img/blot-hero-mob-2.svg") no-repeat center center;
    top: -130px;
    left: 0;
    width: 100vw;
    height: 800px;
    background-size: cover;
    margin-left: -10px;
    margin-right: -10px;
  }
}

.hero {
  padding-bottom: 168px;
}

@media (max-width: 992px) {
  .hero {
    padding-bottom: 134px;
  }
}

@media (max-width: 575px) {
  .hero {
    padding-bottom: 93px;
  }
}

@media (min-width: 1300px) {
  .hero .row .col-xl-9 {
    flex: 0 0 70%;
    max-width: 70%;
  }
  .hero .row .col-xl-3 {
    flex: 0 0 30%;
    max-width: 30%;
  }
}

.hero .wrapper-bottom {
  display: flex;
}

.hero .wrapper-bottom .left-box {
  max-width: 440px;
  margin-right: 25px;
}

@media (max-width: 768px) {
  .hero .wrapper-bottom .left-box {
    margin-right: 0;
  }
}

.hero .wrapper-bottom .left-box .subtitle {
  font-size: 16px;
  line-height: 1.3;
  color: #222222;
}

@media (max-width: 992px) {
  .hero .wrapper-bottom .left-box .subtitle {
    line-height: 1.5;
  }
}

.hero .wrapper-bottom .left-box .btn {
  margin-top: 45px;
}

@media (max-width: 992px) {
  .hero .wrapper-bottom .left-box .btn {
    margin-top: 28px;
    margin-bottom: 69px;
  }
}

@media (max-width: 768px) {
  .hero .wrapper-bottom .left-box .btn {
    margin-top: 39px;
    width: 100%;
    justify-content: center;
  }
}

.hero .wrapper-bottom .qrcode {
  max-width: 160px;
  position: relative;
  z-index: 9;
}

@media (max-width: 992px) {
  .hero .wrapper-bottom .qrcode {
    display: none;
  }
}

.hero .content-box {
  padding-top: 125px;
}

@media (max-width: 992px) {
  .hero .content-box {
    padding-top: 98px;
  }
}

@media (max-width: 575px) {
  .hero .content-box {
    padding-top: 69px;
  }
}

.stats {
  padding-bottom: 160px;
}

@media (max-width: 992px) {
  .stats {
    padding-bottom: 99px;
  }
}

@media (max-width: 575px) {
  .stats {
    padding-bottom: 82px;
  }
}

@media (max-width: 575px) {
  .stats .col-xl-4:last-child {
    margin-top: 25px;
  }
}

.stats .item .title {
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  color: #589CE6;
  margin-bottom: 19px;
}

@media (max-width: 992px) {
  .stats .item .title {
    font-size: 16px;
  }
}

.stats .item .subtitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  color: #222222;
}

.wdroider-functions {
  padding-bottom: 75px;
}

@media (max-width: 992px) {
  .wdroider-functions {
    padding-bottom: 61px;
  }
}

@media (max-width: 575px) {
  .wdroider-functions {
    padding-bottom: 38px;
  }
}

.wdroider-functions .item {
  margin-bottom: 60px;
}

@media (max-width: 992px) {
  .wdroider-functions .item {
    margin-bottom: 40px;
  }
}

.wdroider-functions .item .slash {
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  color: #B235FF;
  margin-bottom: 38px;
}

@media (max-width: 992px) {
  .wdroider-functions .item .slash {
    margin-bottom: 27px;
  }
}

.wdroider-functions .item .title-h4 {
  font-weight: 600;
  font-size: 22px;
  line-height: 1.3;
  color: #262C37;
  margin-bottom: 35px;
  display: flex;
  height: 57px;
  align-items: baseline;
}

@media (max-width: 992px) {
  .wdroider-functions .item .title-h4 {
    font-size: 18px;
  }
}

@media (max-width: 575px) {
  .wdroider-functions .item .title-h4 {
    height: auto;
    margin-bottom: 20px;
  }
  .wdroider-functions .item .title-h4 br {
    display: none;
  }
}

.wdroider-functions .item .title-h4 .badge {
  margin-left: 5px;
  font-weight: 600;
  font-size: 22px;
  color: #589CE6;
  text-transform: uppercase;
}

.wdroider-functions .item p {
  max-width: 550px;
  line-height: 1.4;
}

.no-ads {
  background: #F2F6FF;
  padding-top: 94px;
  overflow: hidden;
}

@media (max-width: 992px) {
  .no-ads {
    padding-top: 64px;
  }
}

@media (max-width: 575px) {
  .no-ads {
    padding-top: 47px;
  }
}

.no-ads .content-box {
  padding-top: 35px;
}

.no-ads .content-box .title-h2 {
  margin-bottom: 32px;
}

.no-ads .content-box p {
  max-width: 500px;
  margin-bottom: 48px;
}

@media (max-width: 575px) {
  .no-ads .content-box p {
    margin-bottom: 31px;
  }
}

.no-ads .smart {
  margin-bottom: -75px;
  margin-left: 10px;
}

@media (max-width: 992px) {
  .no-ads .smart {
    margin-top: 55px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -106px;
    width: 265px;
    height: auto;
  }
}

.no-ads .smart img {
  width: 100%;
  height: 100%;
}

@media (max-width: 992px) {
  .no-ads .smart img {
    padding: 15px;
  }
}

@media (max-width: 992px) {
  .no-ads .smart:after {
    border: 15px solid #6e778d;
  }
}

.no-ads .smart:before {
  top: -230px;
}

@media (max-width: 992px) {
  .no-ads .smart:before {
    top: -100px;
  }
}

@media (max-width: 575px) {
  .no-ads.no-ads-border .smart {
    width: 164px;
    height: 345px;
    margin-bottom: -51px;
  }
}

.no-ads.no-ads-border .smart:after {
  border-color: #DEE7FF;
}

@media (max-width: 575px) {
  .no-ads.no-ads-border .smart:after {
    border: 10px solid #DEE7FF;
    border-radius: 10px;
  }
}

@media (max-width: 575px) {
  .no-ads.no-ads-border .smart img {
    padding: 10px;
  }
}

.no-ads.app .content-box {
  padding-top: 79px;
}

@media (max-width: 992px) {
  .no-ads.app .content-box {
    padding-top: 35px;
  }
}

.no-ads.app .content-box .title-h2 {
  margin-bottom: 40px;
}

@media (max-width: 992px) {
  .no-ads.app .content-box .title-h2 {
    max-width: 500px;
    margin-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .no-ads.app .content-box .title-h2 {
    max-width: 200px;
  }
}

@media (max-width: 992px) {
  .no-ads.app .smart {
    width: 200px;
    margin-bottom: -106px;
    margin-top: 80px;
  }
}

@media (max-width: 575px) {
  .no-ads.app .smart {
    left: -15px;
  }
}

@media (max-width: 450px) {
  .no-ads.app .smart {
    margin-bottom: -200px;
  }
}

@media (max-width: 992px) {
  .no-ads.app .smart:before {
    top: -163px;
    background: url("../img/blot-hero-mob.svg") no-repeat;
  }
}

@media (max-width: 450px) {
  .no-ads.app .smart:before {
    background: url("../img/blot-hero-mob-2.svg") no-repeat;
    left: -73px;
    background-size: cover;
  }
}

.no-ads.app .wrapper-btn {
  display: flex;
}

.no-ads.app .wrapper-btn .qrcode {
  max-width: 166px;
  margin-left: 90px;
  position: relative;
  top: -70px;
}

@media (max-width: 992px) {
  .no-ads.app .wrapper-btn .qrcode {
    display: none;
  }
}

@media (max-width: 992px) {
  .no-ads.notice-banner {
    padding-top: 39px;
  }
}

@media (max-width: 575px) {
  .no-ads.notice-banner {
    padding-top: 12px;
  }
}

@media (max-width: 992px) {
  .no-ads.notice-banner .content-box p {
    max-width: 300px;
  }
  .no-ads.notice-banner .content-box p br {
    display: none;
  }
}

.no-ads.notice-banner .smart {
  margin-bottom: -269px;
}

@media (max-width: 992px) {
  .no-ads.notice-banner .smart {
    margin-bottom: -91px;
    width: 183px;
    height: 397px;
    left: -15px;
  }
}

.no-ads.notice-banner .smart:after {
  border-color: #DEE7FF;
}

.no-ads.notice-banner .smart:before {
  background-image: url("../img/blot-notice.svg");
  width: 990px;
  height: 400px;
  top: -94px;
}

@media (max-width: 992px) {
  .no-ads.notice-banner .smart:before {
    background-image: url("../img/blot-hero-mob-3.svg");
  }
}

@media (max-width: 768px) {
  .no-ads.notice-banner .smart:before {
    background-image: url("../img/blot-hero-mob-4.svg");
    left: -173px;
    background-size: cover;
  }
}

@media (max-width: 450px) {
  .no-ads.notice-banner .smart:before {
    background-size: cover;
    width: 120vw;
    height: 180%;
    left: -46.5%;
    top: 0;
  }
}

.no-ads.notice-banner .content-box {
  padding-top: 0;
  margin-top: -14px;
}

@media (max-width: 992px) {
  .no-ads.notice-banner .content-box {
    margin-top: 40px;
  }
}

.no-ads.notice-banner .content-box .title-h2 {
  margin-bottom: 22px;
}

.privacy-security {
  padding: 130px 0 160px 0;
}

@media (max-width: 992px) {
  .privacy-security {
    padding: 100px 0 93px 0;
  }
}

@media (max-width: 575px) {
  .privacy-security {
    padding: 81px 0 70px 0;
  }
}

.privacy-security .circle {
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  border: 1px solid #B235FF;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 40px;
  font-family: 'Inter', sans-serif;
}

@media (max-width: 992px) {
  .privacy-security .row {
    justify-content: center;
  }
}

@media (max-width: 575px) {
  .privacy-security .row .col-xl-4 {
    margin-bottom: 10px;
  }
}

.personal-information {
  padding-bottom: 160px;
  position: relative;
}

@media (max-width: 992px) {
  .personal-information {
    padding-bottom: 125px;
  }
}

@media (max-width: 575px) {
  .personal-information {
    padding-bottom: 80px;
  }
}

.personal-information:after {
  content: '';
  position: absolute;
  background: url("../img/blot-1.svg") no-repeat;
  width: 741px;
  height: 741px;
  left: 50%;
  transform: translate(-50%, 0);
  margin-left: 450px;
  bottom: -47px;
  pointer-events: none;
  z-index: -1;
}

@media (max-width: 992px) {
  .personal-information:after {
    margin-left: 250px;
    bottom: -142px;
  }
}

@media (max-width: 575px) {
  .personal-information:after {
    content: none;
  }
}

.personal-information .row p {
  line-height: 1.6;
  max-width: 510px;
  font-family: 'Inter', sans-serif;
}

@media (max-width: 992px) {
  .personal-information .row p {
    max-width: none;
    margin-bottom: 30px;
  }
}

@media (max-width: 992px) {
  .personal-information .title-h2 {
    margin-bottom: 35px;
  }
}

.module-info-window {
  background: #F2F6FF;
  border-radius: 10px;
  padding: 52px 40px 67px 40px;
  max-width: 818px;
  margin-top: 40px;
  position: relative;
}

@media (max-width: 992px) {
  .module-info-window {
    margin-top: 6px;
    max-width: 540px;
    padding: 41px 70px 34px 28px;
  }
}

.module-info-window p {
  font-style: italic;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  color: #222222;
  margin-bottom: 40px;
}

@media (max-width: 992px) {
  .module-info-window p {
    max-width: 300px;
    line-height: 1.5;
    margin-bottom: 23px;
  }
}

.module-info-window pre {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  color: #222222;
  margin-left: -15px;
  font-family: 'Inter', sans-serif;
}

@media (max-width: 992px) {
  .module-info-window pre {
    white-space: pre-line;
    word-break: break-word;
    margin-left: 2px;
    line-height: 1.5;
  }
}

.module-info-window pre .text-1 {
  color: #005597;
}

.module-info-window pre .text-2 {
  color: #B235FF;
}

.module-info-window pre .text-3 {
  color: #006434;
}

@media (max-width: 575px) {
  .module-info-window:after {
    content: '';
    position: absolute;
    background: url("../img/blot-1.svg") no-repeat;
    width: 741px;
    height: 741px;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    pointer-events: none;
    z-index: -1;
  }
}

.safety {
  padding-bottom: 160px;
  position: relative;
}

@media (max-width: 992px) {
  .safety {
    padding-bottom: 98px;
  }
}

@media (max-width: 575px) {
  .safety {
    padding-bottom: 81px;
  }
}

@media (max-width: 992px) {
  .safety .title-h2 {
    max-width: 500px;
    margin-bottom: 38px;
  }
}

.safety:before {
  content: '';
  position: absolute;
  background: url("../img/blot-2.svg") no-repeat;
  width: 741px;
  height: 741px;
  left: 50%;
  transform: translate(-50%, 0);
  margin-left: -450px;
  bottom: -47px;
  pointer-events: none;
  z-index: -1;
}

@media (max-width: 992px) {
  .safety:before {
    content: none;
  }
}

.safety .content-box {
  margin-left: auto;
  max-width: 420px;
  font-family: 'Inter', sans-serif;
  margin-right: 140px;
}

@media (max-width: 992px) {
  .safety .content-box {
    max-width: none;
    margin-right: 0;
  }
}

.safety .content-box p {
  margin-bottom: 28px;
  line-height: 1.6;
}

.safety .content-box a {
  transition: .3s;
}

.safety .content-box a:hover {
  opacity: .7;
}

.premium-features {
  padding-bottom: 82px;
}

@media (max-width: 992px) {
  .premium-features {
    padding-bottom: 69px;
  }
}

@media (max-width: 992px) {
  .premium-features .title-h2 {
    margin-bottom: 38px;
  }
}

.premium-features .text {
  max-width: 41%;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.6;
  color: #262C37;
  font-family: 'Inter', sans-serif;
  margin-bottom: 60px;
}

@media (max-width: 992px) {
  .premium-features .text {
    max-width: none;
    margin-bottom: 35px;
  }
}

.premium-features .title-h3 {
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #262C37;
  margin-bottom: 30px;
  font-family: 'Inter', sans-serif;
}

@media (max-width: 992px) {
  .premium-features .title-h3 {
    font-size: 18px;
  }
}

.premium-features .item {
  margin-bottom: 60px;
}

@media (max-width: 992px) {
  .premium-features .item {
    margin-bottom: 30px;
  }
}

.premium-features .item .number {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #589CE6;
  margin-bottom: 20px;
}

@media (max-width: 992px) {
  .premium-features .item .number {
    margin-bottom: 9px;
  }
}

.premium-features .item p {
  font-family: 'Inter', sans-serif;
  line-height: 1.5;
}

.section-single {
  padding-top: 35px;
  position: relative;
}

@media (max-width: 992px) {
  .section-single {
    padding-top: 51px;
  }
}

@media (max-width: 450px) {
  .section-single:before {
    content: '';
    top: -50px;
    left: 0;
    position: absolute;
    width: 120vw;
    height: 100%;
    background: url("../img/blot-hero-mob-5.svg") no-repeat;
    z-index: -1;
    background-size: contain;
  }
}

.section-single h1 {
  font-weight: 600;
  font-size: 46px;
  line-height: 1.4;
  color: #283148;
  margin-bottom: 55px;
  max-width: 90%;
}

@media (max-width: 992px) {
  .section-single h1 {
    font-size: 36px;
    line-height: 1.3;
    margin-bottom: 36px;
  }
}

@media (max-width: 575px) {
  .section-single h1 {
    font-size: 26px;
  }
}

.section-single .top-info ul {
  padding: 34px 0 60px 0;
  margin-bottom: 40px;
  border-top: 1px solid #E7EAEF;
  border-bottom: 1px solid #E7EAEF;
  position: relative;
  z-index: 99;
}

@media (max-width: 992px) {
  .section-single .top-info ul {
    padding: 29px 0 60px 0;
  }
}

@media (max-width: 575px) {
  .section-single .top-info ul {
    padding: 18px 0 54px 0;
  }
}

.section-single .top-info ul li {
  margin-bottom: 5px;
  list-style: none;
}

.section-single .top-info ul li:last-child {
  margin-bottom: 0;
}

.section-single .top-info ul li a {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
  color: #614BFF;
  font-family: 'Inter', sans-serif;
  transition: .3s;
}

.section-single .top-info ul li a:hover {
  color: #222222;
}

.section-single .top-info .top-image img {
  border-radius: 20px;
}

.section-single .top-info .top-image .caption {
  color: #A1A1A1;
  margin-top: 10px;
  line-height: normal;
}

@media (max-width: 575px) {
  .section-single .top-info .top-image .caption {
    font-size: 14px;
  }
}

.section-single h2 {
  font-weight: 600;
  font-size: 32px;
  line-height: 1.4;
  color: #262C37;
  margin-bottom: 50px;
  margin-top: 80px;
}

@media (max-width: 992px) {
  .section-single h2 {
    font-size: 28px;
    margin-bottom: 28px;
    margin-top: 55px;
  }
}

@media (max-width: 575px) {
  .section-single h2 {
    font-size: 20px;
  }
}

.section-single h2:first-of-type {
  margin-top: 0;
}

.section-single h3 {
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #262C37;
  border-top: 1px solid #E7EAEF;
  padding-bottom: 26px;
}

.section-single .module-alert {
  background: #F2F6FF;
  border-radius: 10px;
  padding: 35px 40px 35px 30px;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

@media (max-width: 575px) {
  .section-single .module-alert {
    flex-direction: column;
    align-items: flex-start;
  }
}

.section-single .module-alert .icon {
  flex-shrink: 0;
  margin-right: 30px;
}

@media (max-width: 575px) {
  .section-single .module-alert .icon {
    margin-right: 0;
    margin-bottom: 25px;
  }
}

.section-single p {
  line-height: 1.55;
}

.section-single p a {
  color: #614BFF;
  transition: .3s;
}

.section-single p a:hover {
  color: #222222;
}

.section-single.section-single-pl .top-info {
  margin-bottom: 75px;
}

@media (max-width: 992px) {
  .section-single.section-single-pl .top-info {
    margin-bottom: 44px;
  }
}

@media (max-width: 575px) {
  .section-single.section-single-pl .top-info {
    margin-bottom: 56px;
  }
}

.section-single.section-single-pl .top-info h2 {
  margin-bottom: 15px;
}

@media (max-width: 992px) {
  .section-single.section-single-pl .top-info h2 {
    margin-bottom: 29px;
  }
}

@media (max-width: 575px) {
  .section-single.section-single-pl .top-info h2 {
    font-size: 20px;
  }
}

.section-single.section-single-pl .top-info ul {
  margin-left: 300px;
}

@media (max-width: 992px) {
  .section-single.section-single-pl .top-info ul {
    margin-left: 0;
  }
}

.section-single.section-single-pl .top-info .top-image {
  margin-left: 300px;
  position: relative;
}

@media (max-width: 992px) {
  .section-single.section-single-pl .top-info .top-image {
    margin-left: 0;
  }
}

.section-single.section-single-pl .top-info .top-image:after {
  content: '';
  position: absolute;
  background: url("../img/blot-single.svg") no-repeat;
  width: 741px;
  height: 741px;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-left: -550px;
  top: 55%;
  pointer-events: none;
  z-index: -1;
}

@media (max-width: 992px) {
  .section-single.section-single-pl .top-info .top-image:after {
    margin-left: 250px;
    top: -40%;
    z-index: 1;
  }
}

@media (max-width: 450px) {
  .section-single.section-single-pl .top-info .top-image:after {
    content: none;
  }
}

.section-single.section-single-pl .inner-box > p {
  margin-bottom: 40px;
}

@media (max-width: 992px) {
  .section-single.section-single-pl .inner-box > p:first-of-type {
    margin-bottom: 0;
  }
}

.section-single.section-single-pl .inner-box > p:last-child {
  margin-bottom: 0;
}

.section-single.section-single-pl .inner-box > h3 {
  padding-top: 30px;
}

.section-single.section-single-pl .inner-box > p, .section-single.section-single-pl .inner-box .module-alert, .section-single.section-single-pl .inner-box > h3, .section-single.section-single-pl .inner-box > .wrapper-posts {
  margin-left: 300px;
}

@media (max-width: 992px) {
  .section-single.section-single-pl .inner-box > p, .section-single.section-single-pl .inner-box .module-alert, .section-single.section-single-pl .inner-box > h3, .section-single.section-single-pl .inner-box > .wrapper-posts {
    margin-left: 0;
  }
}

.section-single.section-single-pl.section-single-2 {
  padding-top: 68px;
}

@media (max-width: 992px) {
  .section-single.section-single-pl.section-single-2 {
    padding-top: 51px;
  }
}

.section-single.section-single-pl.section-single-2 .top-image {
  margin-left: 0;
}

.section-single.section-single-pl.section-single-2 .top-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section-single.section-single-pl.section-single-2 .top-image:after {
  content: none;
}

.section-single.section-single-pl.section-single-2 .single-image {
  margin-bottom: 50px;
}

.section-single.section-single-pl.section-single-2 h1 {
  line-height: 1.33;
  margin-bottom: 68px;
}

@media (max-width: 992px) {
  .section-single.section-single-pl.section-single-2 h1 {
    margin-bottom: 36px;
  }
}

.section-single.section-single-pl.section-single-2 .top-info {
  margin-bottom: 86px;
}

@media (max-width: 575px) {
  .section-single.section-single-pl.section-single-2 .top-info {
    margin-bottom: 56px;
  }
}

.mb-1 .inner-border {
  border-bottom: 1px solid #E7EAEF;
  padding-bottom: 100px;
}

@media (max-width: 992px) {
  .mb-1 .inner-border {
    padding-bottom: 30px;
    margin-bottom: 80px;
  }
}

@media (max-width: 575px) {
  .mb-1 .inner-border {
    margin-bottom: 40px;
  }
}

.my-licenses {
  padding-top: 34px;
}

@media (max-width: 992px) {
  .my-licenses .inner-border {
    border: none;
    padding-bottom: 0;
  }
}

.my-licenses h1 {
  font-weight: 600;
  font-size: 46px;
  line-height: 1.5;
  color: #262C37;
  margin-bottom: 37px;
}

@media (max-width: 992px) {
  .my-licenses h1 {
    font-size: 36px;
  }
}

@media (max-width: 575px) {
  .my-licenses h1 {
    font-size: 26px;
    margin-bottom: 16px;
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 66px;
  font-family: 'Inter', sans-serif;
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-bottom: 37px;
  }
}

@media (max-width: 575px) {
  .breadcrumbs {
    margin-bottom: 42px;
  }
}

.breadcrumbs li {
  list-style: none;
}

.breadcrumbs span {
  color: #8F90A6;
}

.breadcrumbs a {
  color: #222222;
  position: relative;
  margin-right: 4px;
  display: inline-flex;
  transition: .3s;
}

.breadcrumbs a:hover {
  color: #B235FF;
}

.breadcrumbs a:after {
  content: '/';
  color: #222222;
  padding-left: 4px;
}

.module-connected-devices {
  background: #F2F6FF;
  border-radius: 10px;
  padding: 45px 40px;
  display: grid;
  grid-template-columns: 47% 53%;
  position: relative;
}

@media (max-width: 1200px) {
  .module-connected-devices {
    padding: 42px 31px;
    grid-template-columns: 60% 40%;
  }
}

@media (max-width: 992px) {
  .module-connected-devices {
    display: block;
  }
}

@media (max-width: 575px) {
  .module-connected-devices {
    padding: 33px 16px;
  }
}

.module-connected-devices .left-box {
  border-right: 1px solid #E7EAEF;
  margin-right: 60px;
}

@media (max-width: 992px) {
  .module-connected-devices .left-box {
    margin-right: 0;
    border: none;
  }
}

.module-connected-devices .right-box {
  padding-top: 10px;
}

@media (max-width: 992px) {
  .module-connected-devices .right-box {
    padding-top: 34px;
    margin-left: 70px;
  }
}

@media (max-width: 575px) {
  .module-connected-devices .right-box {
    margin-left: 0;
    text-align: center;
  }
}

.module-connected-devices .wdroider {
  display: flex;
  align-items: center;
}

.module-connected-devices .wdroider .icon {
  width: 53px;
  height: 53px;
  background: #589CE6;
  margin-right: 19px;
  flex-shrink: 0;
  font-weight: 600;
  font-size: 22px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
}

@media (max-width: 575px) {
  .module-connected-devices .wdroider .icon {
    margin-right: 14px;
  }
}

.module-connected-devices .wdroider .title {
  font-weight: 600;
  font-size: 32px;
  line-height: 1.4;
  color: #262C37;
}

@media (max-width: 992px) {
  .module-connected-devices .wdroider .title {
    font-size: 24px;
  }
}

@media (max-width: 575px) {
  .module-connected-devices .wdroider .title {
    font-size: 20px;
  }
}

.module-connected-devices .wdroider .sub-title {
  font-size: 16px;
  line-height: 25px;
  color: #435257;
}

.module-connected-devices .info-line {
  font-size: 16px;
  line-height: 25px;
  color: #FF3B87;
  margin-top: 22px;
  margin-left: 70px;
  margin-bottom: 30px;
}

@media (max-width: 992px) {
  .module-connected-devices .info-line {
    margin-top: 30px;
    margin-left: 70px;
    margin-bottom: 33px;
  }
}

@media (max-width: 575px) {
  .module-connected-devices .info-line {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    max-width: 250px;
  }
}

.module-connected-devices .btn {
  margin-left: 70px;
}

@media (max-width: 575px) {
  .module-connected-devices .btn {
    margin-left: 0;
    padding: 0 15px;
    width: 100%;
    text-align: center;
    justify-content: center;
  }
}

.module-connected-devices h3 {
  font-weight: 600;
  font-size: 22px;
  line-height: 1.3;
  color: #262C37;
  margin-bottom: 10px;
}

@media (max-width: 992px) {
  .module-connected-devices h3 {
    font-size: 18px;
    margin-bottom: 24px;
  }
}

@media (max-width: 575px) {
  .module-connected-devices h3 {
    font-size: 16px;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
  }
}

.module-connected-devices .list li {
  list-style: none;
  font-size: 16px;
  line-height: 1.2;
  font-family: 'Inter', sans-serif;
  color: #435257;
  margin-bottom: 5px;
}

@media (max-width: 575px) {
  .module-connected-devices .list li {
    display: flex;
    flex-direction: column;
    margin-bottom: 14px;
  }
}

.module-connected-devices .list li span {
  margin-left: 5px;
  font-size: 13px;
  line-height: 1.2;
  color: #589CE6;
}

.module-connected-devices .list li:last-child {
  margin-bottom: 0;
}

.authorize {
  padding: 40px 0 0 0;
}

.authorize form {
  font-family: 'Inter', sans-serif;
}

.authorize form .btn {
  width: 100%;
  text-align: center;
  justify-content: center;
  margin-bottom: 20px;
}

.authorize form .line {
  margin-bottom: 9px;
  display: flex;
  align-items: center;
}

@media (max-width: 575px) {
  .authorize form .line {
    flex-direction: column;
    align-items: flex-start;
  }
}

.authorize form .line:last-child {
  margin-bottom: 0;
}

.authorize form .link-1 {
  font-size: 16px;
  color: #262C37;
  border-bottom: 0.5px solid #262C37;
  transition: .3s;
  margin: 0 5px;
}

@media (max-width: 575px) {
  .authorize form .link-1 {
    margin: 5px 0 5px 31px;
  }
}

.authorize form .link-1:hover {
  border-bottom: 0.5px solid transparent;
}

.authorize form .link-2 {
  font-size: 16px;
  color: #278EDD;
  transition: .3s;
}

@media (max-width: 575px) {
  .authorize form .link-2 {
    margin: 5px 0 5px 31px;
  }
}

.authorize form .link-2:hover {
  opacity: .7;
}

.authorize form .module__check .link-1 {
  margin: 0;
}

.cookies {
  position: fixed;
  left: 50%;
  bottom: 15px;
  max-width: 900px;
  width: calc(100% - 20px);
  transform: translate(-50%, 0);
  background: #F2F6FF;
  border: 1px solid #DBDEE7;
  border-radius: 10px;
  z-index: 99;
  display: flex;
  align-items: center;
  padding: 25px 40px;
}

@media (max-width: 575px) {
  .cookies {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  }
}

.cookies p {
  font-weight: 600;
  font-size: 22px;
  line-height: 1.3;
  color: #222222;
  margin-right: 56px;
}

@media (max-width: 575px) {
  .cookies p {
    margin-right: 0;
    margin-bottom: 15px;
    font-size: 18px;
  }
}

.cookies p a {
  color: #614BFF;
  transition: .3s;
  border-bottom: 1px solid #614BFF;
}

.cookies p a:hover {
  border-bottom: 1px solid transparent;
}

.module-author {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e7eaef;
  border-bottom: 1px solid #e7eaef;
  margin-top: 70px;
  margin-bottom: 24px;
  padding: 25px 0 43px 0;
}

@media (max-width: 768px) {
  .module-author {
    align-items: flex-start;
  }
}

.module-author .wrapper-author {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .module-author .wrapper-author {
    flex-direction: column;
    align-items: flex-start;
  }
}

.module-author .wrapper-author .author {
  margin-right: 10px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .module-author .wrapper-author .author {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.module-author .wrapper-author .name {
  color: #589CE6;
  transition: .3s;
}

.module-author .wrapper-author .name:hover {
  opacity: .7;
}

.module-author .date {
  color: #435257;
}

.module-author.module-author-2 {
  border: none;
  padding: 0;
  margin-top: 32px;
  margin-bottom: 31px;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #435257;
  margin-bottom: 80px;
}

@media (max-width: 768px) {
  .pagination {
    margin-bottom: 40px;
    flex-direction: column;
    align-items: normal;
  }
}

.pagination .title {
  margin-top: 10px;
  line-height: normal;
}

.pagination a {
  color: #614BFF;
  transition: .3s;
}

.pagination a:hover {
  opacity: .7;
}

.pagination a img {
  transition: .3s;
}

.pagination a img:hover {
  opacity: .7;
}

.pagination .next-post {
  text-align: right;
}

@media (max-width: 768px) {
  .pagination .next-post {
    margin-top: 20px;
  }
}

@media (max-width: 992px) {
  .module-post-related {
    margin-bottom: 30px;
  }
}

.module-post-related .title-h4 {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  color: #262C37;
  margin-bottom: 9px;
  line-height: 1.4;
  min-height: 90px;
}

@media (max-width: 992px) {
  .module-post-related .title-h4 {
    min-height: auto;
  }
  .module-post-related .title-h4 br {
    display: none;
  }
}

@media (max-width: 575px) {
  .module-post-related .title-h4 {
    font-size: 20px;
  }
}

.module-post-related p {
  line-height: 1.6;
}

.read-more {
  margin-top: 20px;
  color: #614BFF;
  display: inline-flex;
  transition: .3s;
}

.read-more:hover {
  opacity: .7;
}

.related-articles .title-h2 {
  margin-bottom: 56px;
}

@media (max-width: 575px) {
  .related-articles .title-h2 {
    margin-bottom: 30px;
  }
}

@media (max-width: 992px) {
  .related-articles .col-xl-4:last-child .module-post-related {
    margin-bottom: 0;
  }
}

.module-post {
  padding-bottom: 60px;
  margin-bottom: 40px;
  border-bottom: 1px solid #e7eaef;
}

.module-post:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}

.module-post .image {
  margin-bottom: 23px;
}

.module-post .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.module-post .title-h4 {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 1.2;
}

main {
  position: relative;
  overflow: hidden;
}

@media (max-width: 575px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
  .container .row {
    margin-left: -10px;
    margin-right: -10px;
  }
  .container .row [class*="col-"] {
    padding-left: 10px;
    padding-right: 10px;
  }
}

header {
  padding-top: 20px;
  transition: .3s;
  padding-bottom: 54px;
}

@media (max-width: 992px) {
  header {
    padding-top: 14px;
    padding-bottom: 0;
  }
}

@media (max-width: 575px) {
  header {
    padding-top: 12px;
  }
}

header .burger {
  display: none;
}

@media (max-width: 992px) {
  header .burger {
    display: flex;
  }
}

header .wrapper-login {
  display: flex;
  align-items: center;
}

@media (max-width: 992px) {
  header .wrapper-login {
    margin-left: -45px;
  }
}

@media (max-width: 575px) {
  header .wrapper-login {
    margin-left: 0;
  }
}

header .wrapper-login .header-login {
  margin-right: 53px;
}

@media (max-width: 575px) {
  header .wrapper-login .header-login {
    margin-right: 40px;
  }
}

header .close_menu {
  display: none;
  width: 24px;
  height: 24px;
  z-index: 99999;
}

header .container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 992px) {
  header .container {
    justify-content: space-between;
  }
}

header .logo {
  position: relative;
  z-index: 999;
}

@media (max-width: 575px) {
  header .logo {
    max-width: 80px;
  }
}

header .logo img {
  transition: .3s;
}

header.header-custom {
  padding-bottom: 24px;
}

@media (max-width: 992px) {
  header.header-custom {
    padding-bottom: 13px;
  }
}

header.header-custom-2 {
  padding-bottom: 24px;
}

header.header-custom-2 .container {
  position: relative;
}

header.header-custom-2 .container:before {
  content: '';
  max-width: 1160px;
  width: calc(100% - 30px);
  height: 1px;
  background: #e7eaef;
  display: block;
  position: absolute;
  left: 15px;
  bottom: -25px;
}

.input_def {
  width: 100%;
  height: 60px;
  background: #FFFFFF;
  border: 1px solid #222222;
  border-radius: 10px;
  padding: 22px;
  font-size: 16px;
  letter-spacing: 0.04em;
  color: #262C37;
  font-family: 'Inter', sans-serif;
  display: block;
}

@media (max-width: 575px) {
  .input_def {
    height: 40px;
  }
}

::-webkit-input-placeholder {
  color: #000;
}

::-moz-placeholder {
  color: #000;
}

:-ms-input-placeholder {
  color: #000;
}

:-moz-placeholder {
  color: #000;
}

.module__check input, .module__radio input {
  display: none;
}

.module__check {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.module__check .text {
  font-size: 16px;
  line-height: 1.2;
  color: #262C37;
  font-family: 'Inter', sans-serif;
}

.module__check .check {
  width: 18px;
  height: 18px;
  border: 1px solid #262C37;
  border-radius: 3px;
  margin-right: 13px;
  flex-shrink: 0;
}

.module__check input:checked + .check {
  background: #fff url("../img/checkbox-icon.svg") no-repeat center center;
}

.module-authorize {
  max-width: 600px;
  width: 100%;
  background: #F2F6FF;
  border-radius: 10px;
  margin: 0 auto 30px auto;
  padding: 50px 70px;
  position: relative;
}

@media (max-width: 992px) {
  .module-authorize {
    padding: 30px;
  }
}

.module-authorize:last-of-type {
  margin-bottom: 0;
}

.module-authorize.blot-2:before {
  content: '';
  position: absolute;
  background: url("../img/blot-2.svg") no-repeat;
  width: 741px;
  height: 741px;
  left: 50%;
  transform: translate(-50%, 0);
  margin-left: -350px;
  top: 0;
  pointer-events: none;
  z-index: -1;
}

@media (max-width: 575px) {
  .module-authorize.blot-2:before {
    content: none;
  }
}

.module-authorize.blot-1:after {
  content: '';
  position: absolute;
  background: url("../img/blot-1.svg") no-repeat;
  width: 741px;
  height: 741px;
  left: 50%;
  transform: translate(-50%, 0);
  margin-left: 350px;
  top: 50px;
  pointer-events: none;
  z-index: -1;
}

@media (max-width: 575px) {
  .module-authorize.blot-1:after {
    content: none;
  }
}

.module-authorize h3 {
  font-weight: 600;
  font-size: 32px;
  line-height: 1.3;
  color: #222222;
  margin-bottom: 30px;
}

@media (max-width: 992px) {
  .module-authorize h3 {
    font-size: 26px;
  }
}

.module-authorize .sign-google {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  transition: .3s;
}

@media (max-width: 575px) {
  .module-authorize .sign-google {
    height: 50px;
    margin-bottom: 20px;
  }
}

.module-authorize .sign-google:hover {
  opacity: .7;
}

.module-authorize .sign-google .icon {
  background: #FFFFFF;
  border: 1px solid #222222;
  border-right: transparent;
  border-radius: 10px 0 0 10px;
  height: 100%;
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.module-authorize .sign-google .text {
  background: #614BFF;
  border-radius: 0 10px 10px 0;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  padding: 0 20px;
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
}

.module-authorize .module-robot {
  width: 100%;
  height: 83px;
  background: #FFFFFF;
  border: 1px solid #222222;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 28px;
  margin-bottom: 30px;
}

@media (max-width: 575px) {
  .module-authorize .module-robot {
    height: 50px;
  }
  .module-authorize .module-robot img {
    height: 30px;
  }
}

.module-authorize .g-recaptcha {
  margin-bottom: 30px;
}

@media (max-width: 575px) {
  .module-authorize .g-recaptcha {
    margin-bottom: 20px;
  }
}

fieldset {
  margin-bottom: 30px;
}

@media (max-width: 575px) {
  fieldset {
    margin-bottom: 20px;
  }
}

fieldset label {
  font-size: 16px;
  line-height: 1.2;
  color: #435257;
  margin-bottom: 5px;
  display: block;
}

.title-h2 {
  font-weight: 600;
  font-size: 46px;
  line-height: 1.3;
  color: #262C37;
  margin-bottom: 70px;
  position: relative;
  z-index: 1;
}

@media (max-width: 1300px) {
  .title-h2 br {
    display: none;
  }
}

@media (max-width: 992px) {
  .title-h2 {
    font-size: 36px;
    margin-bottom: 57px;
  }
}

@media (max-width: 575px) {
  .title-h2 {
    margin-bottom: 30px;
    font-size: 26px;
  }
}

.title-h1 {
  font-weight: 600;
  font-size: 46px;
  line-height: 1.3;
  color: #262C37;
  margin-bottom: 29px;
  position: relative;
  z-index: 1;
}

@media (max-width: 1300px) {
  .title-h1 br {
    display: none;
  }
}

@media (max-width: 992px) {
  .title-h1 {
    font-size: 36px;
  }
}

@media (max-width: 575px) {
  .title-h1 {
    font-size: 25px;
  }
}

p {
  font-size: 16px;
  line-height: 1.4;
  color: #222222;
}

.btn {
  display: inline-flex;
  align-items: center;
  transition: .3s;
  height: 69px;
  background: #614BFF;
  border-radius: 50px;
  padding: 0 45px;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  color: #FFFFFF;
  white-space: nowrap;
  position: relative;
  z-index: 99;
}

@media (max-width: 575px) {
  .btn {
    text-align: center;
    justify-content: center;
    width: 100%;
  }
}

.btn:hover {
  opacity: .7;
}

.btn.btn-border {
  color: #614BFF;
  border: 2px solid #614BFF;
  background: none;
}

.btn.btn-border:hover {
  opacity: 1;
  background: #614BFF;
  color: #FFFFFF;
}

.module__reviews {
  height: 357px;
  background: #FFFFFF;
  border: 2px solid #F0EBE6;
  border-radius: 30px;
  padding: 32px;
}

.swiper-container {
  position: relative;
}

.slider_1_css .swiper-slide {
  width: 303.75px;
}

.slider_2_css {
  overflow: hidden;
}

.slider_2_css .swiper-pagination span {
  background: none;
}

.white-popup {
  position: relative;
  background: #FFF;
  padding: 20px;
  width: auto;
  max-width: 1200px;
  margin: 20px auto;
  height: 600px;
}

/* overlay at start */
.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

.main_menu {
  margin-left: 12px;
}

@media (max-width: 992px) {
  .main_menu {
    margin-left: 0;
    position: fixed;
    left: 0;
    top: 0;
    background: #fff;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    transition: .3s;
    pointer-events: none;
    padding-top: 102px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 9999;
  }
  .main_menu.active {
    pointer-events: visible;
    transform: translateX(0);
  }
}

@media (max-width: 992px) {
  .main_menu .icon {
    transform: rotate(270deg);
  }
}

.main_menu > ul {
  display: flex;
  align-items: center;
}

@media (max-width: 992px) {
  .main_menu > ul {
    flex-direction: column;
    width: 100%;
    max-width: 300px;
  }
}

.main_menu > ul > li {
  list-style: none;
  margin-right: 42px;
  position: relative;
}

@media (max-width: 1200px) {
  .main_menu > ul > li {
    margin-right: 20px;
  }
}

@media (max-width: 992px) {
  .main_menu > ul > li {
    margin-right: 0;
    margin-bottom: 24px;
  }
}

.main_menu > ul > li:last-child {
  margin-right: 0;
}

@media (max-width: 992px) {
  .main_menu > ul > li:last-child {
    margin-bottom: 0;
  }
}

.main_menu > ul > li > a {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #262C37;
  transition: .3s;
}

@media (max-width: 992px) {
  .main_menu > ul > li > a {
    font-size: 17px;
  }
}

.main_menu > ul > li > a:hover {
  color: #B235FF;
}

.main_menu.main_menu_footer > ul {
  justify-content: flex-end;
}

.main_menu.main_menu_footer > ul > li > a {
  color: #FFFFFF;
}

.main_menu.main_menu_footer > ul > li > a:hover {
  color: #4BBD8C;
}

@media (max-width: 992px) {
  .main_menu.main_menu_2 {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}

.nesting > a {
  display: flex;
  align-items: center;
}

.nesting > a span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  transition: .3s;
}

.nesting:hover .sub_menu {
  opacity: 1;
  pointer-events: visible;
}

.nesting:hover .icon {
  transform: rotate(180deg);
}

.sub_menu {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  padding: 24px 32px;
  background: #FFFFFF;
  box-shadow: 0px 10px 20px rgba(124, 124, 124, 0.15);
  border-radius: 20px;
  top: 105%;
  left: 50%;
  transform: translate(-50%, 0);
  min-width: 128px;
  transition: .3s;
  z-index: 99;
}

.sub_menu li {
  list-style: none;
  margin-bottom: 24px;
  white-space: nowrap;
  text-align: left;
}

.sub_menu li:last-child {
  margin-bottom: 0;
}

.sub_menu li a {
  font-size: 17px;
  color: #65656C;
  transition: .3s;
}

.sub_menu li a:hover {
  color: #4BBD8C;
}

footer {
  padding: 38px 0 25px 0;
}

@media (max-width: 992px) {
  footer {
    padding: 46px 0;
  }
}

@media (max-width: 575px) {
  footer {
    padding: 39px 0 25px 0;
    text-align: center;
  }
}

@media (max-width: 575px) {
  footer .col-xl-5 {
    order: 2;
  }
}

@media (max-width: 575px) {
  footer .col-xl-4 {
    order: 1;
  }
}

footer .wrapper-menu {
  display: flex;
  margin-bottom: 37px;
}

@media (max-width: 575px) {
  footer .wrapper-menu {
    justify-content: space-between;
  }
}

footer .wrapper-menu .menu-footer {
  margin-right: 76px;
}

@media (max-width: 992px) {
  footer .wrapper-menu .menu-footer {
    margin-right: 42px;
  }
}

footer .wrapper-menu .menu-footer:last-child {
  margin-right: 0;
}

footer .wrapper-menu .menu-footer li {
  list-style: none;
  margin-bottom: 23px;
}

@media (max-width: 575px) {
  footer .wrapper-menu .menu-footer li {
    margin-bottom: 18px;
  }
}

footer .wrapper-menu .menu-footer li a {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: #222222;
  display: inline-flex;
  font-family: 'Inter', sans-serif;
  transition: .3s;
}

footer .wrapper-menu .menu-footer li a:hover {
  color: #B235FF;
}

footer .mail {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  color: #222222;
  transition: .3s;
}

@media (max-width: 992px) {
  footer .mail {
    position: relative;
    left: -20px;
  }
}

@media (max-width: 575px) {
  footer .mail {
    position: static;
    margin: 30px 0 40px 0;
    display: inline-flex;
  }
}

footer .mail:hover {
  color: #B235FF;
}

footer .bottom-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #E7EAEF;
  padding-top: 21px;
}

@media (max-width: 575px) {
  footer .bottom-line {
    flex-direction: column;
  }
}

footer .bottom-line .copyright {
  font-size: 13px;
  line-height: 20px;
  color: #878787;
  font-family: 'Inter', sans-serif;
}

@media (max-width: 575px) {
  footer .bottom-line .copyright {
    margin-bottom: 30px;
  }
}

footer .bottom-line .bottom-menu {
  display: flex;
  align-items: center;
  margin-right: 165px;
}

@media (max-width: 992px) {
  footer .bottom-line .bottom-menu {
    margin-right: 98px;
  }
}

@media (max-width: 575px) {
  footer .bottom-line .bottom-menu {
    margin-right: 0;
  }
}

footer .bottom-line .bottom-menu li {
  list-style: none;
  margin-right: 15px;
}

footer .bottom-line .bottom-menu li:last-child {
  margin-right: 0;
}

footer .bottom-line .bottom-menu li a {
  font-size: 13px;
  line-height: 20px;
  color: #878787;
  font-family: 'Inter', sans-serif;
  transition: .3s;
}

footer .bottom-line .bottom-menu li a:hover {
  color: #B235FF;
}
