.module__reviews {
	height: 357px;
	background: #FFFFFF;
	border: 2px solid #F0EBE6;
	border-radius: 30px;
	padding: 32px;
}

.swiper-container {
	position: relative;
}

.slider_1_css {
	.swiper-slide {
		width: 303.75px;
	}
}

.slider_2_css {
	overflow: hidden;

	.swiper-pagination {
		span {
			background: none;
		}
	}
}
