.input_def {
	width: 100%;
	height: 60px;
	background: #FFFFFF;
	border: 1px solid #222222;
	border-radius: 10px;
	padding: 22px;
	font-size: 16px;
	letter-spacing: 0.04em;
	color: #262C37;
	font-family: 'Inter', sans-serif;
	display: block;
	@include media-breakpoint-down-575 {
		height: 40px;
	}
}

::-webkit-input-placeholder {
	color: #000;
}

::-moz-placeholder {
	color: #000;
}

:-ms-input-placeholder {
	color: #000;
}

:-moz-placeholder {
	color: #000;
}

.module__check input, .module__radio input {
	display: none;
}

.module__check {
	cursor: pointer;
	display: inline-flex;
	align-items: center;

	.text {
		font-size: 16px;
		line-height: 1.2;
		color: #262C37;
		font-family: 'Inter', sans-serif;
	}

	.check {
		width: 18px;
		height: 18px;
		border: 1px solid #262C37;
		border-radius: 3px;
		margin-right: 13px;
		flex-shrink: 0;
	}

	input:checked + .check {
		background: #fff url("../img/checkbox-icon.svg") no-repeat center center;
	}
}

.module-authorize {
	max-width: 600px;
	width: 100%;
	background: #F2F6FF;
	border-radius: 10px;
	margin: 0 auto 30px auto;
	padding: 50px 70px;
	position: relative;
	@include media-breakpoint-down-992 {
		padding: 30px;
	}

	&:last-of-type {
		margin-bottom: 0;
	}

	&.blot-2 {
		&:before {
			content: '';
			position: absolute;
			background: url("../img/blot-2.svg") no-repeat;
			width: 741px;
			height: 741px;
			left: 50%;
			transform: translate(-50%, 0);
			margin-left: -350px;
			top: 0;
			pointer-events: none;
			z-index: -1;
			@include media-breakpoint-down-575 {
				content: none;
			}
		}
	}

	&.blot-1 {
		&:after {
			content: '';
			position: absolute;
			background: url("../img/blot-1.svg") no-repeat;
			width: 741px;
			height: 741px;
			left: 50%;
			transform: translate(-50%, 0);
			margin-left: 350px;
			top: 50px;
			pointer-events: none;
			z-index: -1;
			@include media-breakpoint-down-575 {
				content: none;
			}
		}
	}

	h3 {
		font-weight: 600;
		font-size: 32px;
		line-height: 1.3;
		color: #222222;
		margin-bottom: 30px;
		@include media-breakpoint-down-992 {
			font-size: 26px;
		}
	}

	.sign-google {
		width: 100%;
		height: 60px;
		display: flex;
		align-items: center;
		margin-bottom: 35px;
		transition: .3s;
		@include media-breakpoint-down-575 {
			height: 50px;
			margin-bottom: 20px;
		}

		&:hover {
			opacity: .7;
		}

		.icon {
			background: #FFFFFF;
			border: 1px solid #222222;
			border-right: transparent;
			border-radius: 10px 0 0 10px;
			height: 100%;
			width: 64px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
		}

		.text {
			background: #614BFF;
			border-radius: 0 10px 10px 0;
			font-size: 16px;
			line-height: 26px;
			letter-spacing: 0.04em;
			color: #FFFFFF;
			padding: 0 20px;
			height: 100%;
			display: flex;
			align-items: center;
			width: 100%;
		}
	}

	.module-robot {
		width: 100%;
		height: 83px;
		background: #FFFFFF;
		border: 1px solid #222222;
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 28px;
		margin-bottom: 30px;
		@include media-breakpoint-down-575 {
			height: 50px;
			img {
				height: 30px;
			}
		}
	}

	.g-recaptcha {
		margin-bottom: 30px;
		@include media-breakpoint-down-575 {
			margin-bottom: 20px;
		}
	}
}

fieldset {
	margin-bottom: 30px;
	@include media-breakpoint-down-575 {
		margin-bottom: 20px;
	}

	label {
		font-size: 16px;
		line-height: 1.2;
		color: #435257;
		margin-bottom: 5px;
		display: block;
	}
}
