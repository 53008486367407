header {
	padding-top: 20px;
	transition: .3s;
	padding-bottom: 54px;
	@include media-breakpoint-down-992 {
		padding-top: 14px;
		padding-bottom: 0;
	}
	@include media-breakpoint-down-575 {
		padding-top: 12px;
	}

	.burger {
		display: none;
		@include media-breakpoint-down-992 {
			display: flex;
		}
	}

	.wrapper-login {
		display: flex;
		align-items: center;
		@include media-breakpoint-down-992 {
			margin-left: -45px;
		}
		@include media-breakpoint-down-575 {
			margin-left: 0;
		}

		.header-login {
			margin-right: 53px;
			@include media-breakpoint-down-575 {
				margin-right: 40px;
			}
		}
	}

	.close_menu {
		display: none;
		width: 24px;
		height: 24px;
		z-index: 99999;
	}

	.container {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		@include media-breakpoint-down-992 {
			justify-content: space-between;
		}
	}

	.logo {
		position: relative;
		z-index: 999;
		@include media-breakpoint-down-575 {
			max-width: 80px;
		}

		img {
			transition: .3s;
		}
	}

	&.header-custom {
		padding-bottom: 24px;
		@include media-breakpoint-down-992 {
			padding-bottom: 13px;
		}
	}

	&.header-custom-2 {
		padding-bottom: 24px;

		.container {
			position: relative;

			&:before {
				content: '';
				max-width: 1160px;
				width: calc(100% - 30px);
				height: 1px;
				background: #e7eaef;
				display: block;
				position: absolute;
				left: 15px;
				bottom: -25px;
			}
		}
	}

}
