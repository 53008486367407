footer {
	padding: 38px 0 25px 0;
	@include media-breakpoint-down-992 {
		padding: 46px 0;
	}
	@include media-breakpoint-down-575 {
		padding: 39px 0 25px 0;
		text-align: center;
	}

	.col-xl-5 {
		@include media-breakpoint-down-575 {
			order: 2;
		}
	}

	.col-xl-4 {
		@include media-breakpoint-down-575 {
			order: 1;
		}
	}

	.wrapper-menu {
		display: flex;
		margin-bottom: 37px;
		@include media-breakpoint-down-575 {
			justify-content: space-between;
		}

		.menu-footer {
			margin-right: 76px;
			@include media-breakpoint-down-992 {
				margin-right: 42px;
			}

			&:last-child {
				margin-right: 0;
			}

			li {
				list-style: none;
				margin-bottom: 23px;
				@include media-breakpoint-down-575 {
					margin-bottom: 18px;
				}

				a {
					font-weight: 400;
					font-size: 16px;
					line-height: 1.2;
					color: #222222;
					display: inline-flex;
					font-family: 'Inter', sans-serif;
					transition: .3s;

					&:hover {
						color: #B235FF;
					}
				}
			}
		}
	}

	.mail {
		font-weight: 600;
		font-size: 16px;
		line-height: 1.2;
		color: #222222;
		transition: .3s;
		@include media-breakpoint-down-992 {
			position: relative;
			left: -20px;
		}
		@include media-breakpoint-down-575 {
			position: static;
			margin: 30px 0 40px 0;
			display: inline-flex;
		}

		&:hover {
			color: #B235FF;
		}
	}

	.bottom-line {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-top: 1px solid #E7EAEF;
		padding-top: 21px;
		@include media-breakpoint-down-575 {
			flex-direction: column;
		}

		.copyright {
			font-size: 13px;
			line-height: 20px;
			color: #878787;
			font-family: 'Inter', sans-serif;
			@include media-breakpoint-down-575 {
				margin-bottom: 30px;
			}
		}

		.bottom-menu {
			display: flex;
			align-items: center;
			margin-right: 165px;
			@include media-breakpoint-down-992 {
				margin-right: 98px;
			}
			@include media-breakpoint-down-575 {
				margin-right: 0;
			}

			li {
				list-style: none;
				margin-right: 15px;

				&:last-child {
					margin-right: 0;
				}

				a {
					font-size: 13px;
					line-height: 20px;
					color: #878787;
					font-family: 'Inter', sans-serif;
					transition: .3s;

					&:hover {
						color: #B235FF;
					}
				}
			}
		}
	}
}
